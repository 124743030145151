import React, { Component, useState, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { XPanel, PageTitle } from '../../components'
import Moment from 'moment'
//import Dygraph from 'dygraphs';
import Dygraph from '../../../dygraphs'
import Spinner from '../../indicators/spinner.js'
import PastDatePick from './pastdatepick.js'
import './dataanalysis.css'

import api from '../../util/api.js'

const { getDevice, getBulkData } = api

import constants from '../../../config/constants.js'
import DataArrBuilder from './util/dataarrbuilder.js'
import data from './util/dataarrbuilder.js'

console.log('DataArrBuilder', DataArrBuilder)

const modeMap = {
  '1': 'Ventilation',
  '2': 'Economzier',
  '3': 'Actuator',
}

const dygraphOptsTemp = {
  height: 320,
  labels: [
    'Datetime',
    'Zone Temp',
    'Heat Setpoint',
    'Cool Setpoint',
    'External 1',
    'External 2',
    'External 3',
    'External 4',
  ],
  colors: ['#159B00', '#F21818', '#1C579F', '#C6C6C6', '#8E8E8E', '#5B5B5B', '#383838'],
  labelsDiv: 'legendDivTemp',
  labelsSeparateLines: false,
  legend: 'always',
  animatedZooms: true,
}


const dygraphOptsVoc = {
  height: 320,
  labels: [
    'Datetime',
    'Voc Index',
  ],
  colors: ['#159B00', '#F21818', '#1C579F', '#C6C6C6', '#8E8E8E', '#5B5B5B', '#383838'],
  labelsDiv: 'legendDivVoc',
  labelsSeparateLines: false,
  legend: 'always',
  animatedZooms: true,
}

const dygraphOptsSps = {
  height: 320,
  labels: [
    'Datetime',
    'Mass Concentration',
    'Number Concentration',
  ],
  colors: ['#159B00', '#F21818', '#1C579F', '#C6C6C6', '#8E8E8E', '#5B5B5B', '#383838'],
  labelsDiv: 'legendDivSps',
  labelsSeparateLines: false,
  legend: 'always',
  animatedZooms: true,
}


const dygraphOptsCo2 = {
  height: 320,
  labels: [
    'Datetime', 
    'CO2 1', 
    'CO2 2', 
    'CO2 3', 
    'CO2 4', 
    'Duty'
  ],
  series: {
    // 'CO2 1': {
    //   axis: 'y1'
    // },
    'Duty': {
      axis: 'y2',
      // valueRange: [0, 100]
      // plotter: window.SmoothPlotter,
    },
  },
  axes: {
    y2: {
      valueRange: [0, 110],
      axisLabelFormatter: function(y) {
        return y.toFixed(0)
      },
      axisLabelWidth: 50,
      independentTicks: true,
      ticker: function(min, max, pixels, opts, dygraph, vals) {
        const ticks = []
        for (let i = min; i <= max; i += 10) {
          ticks.push({ v: i, label: i.toFixed(0) })
        }
        return ticks
      }
    }
  },
  colors: ['#383838', '#5B5B5B', '#8E8E8E', '#C6C6C6', '#F21818'],
  //["#C6C6C6", "#8E8E8E", "#5B5B5B", "#383838"],
  labelsDiv: 'legendDivCo2',
  labelsSeparateLines: false,
  legend: 'always',
  animatedZooms: true,
}

function getCo2ChartOptions(cnfg_dcv, datum) {
  const options = cnfg_dcv || {}
  const mode = options.mode || '2'
  console.log('cnfg_dcv', cnfg_dcv)
  console.log('co2 mode', mode)

  let labels = [
    'Datetime', // 0
    'CO2 1', // 1
    'CO2 2', // 2
    'CO2 3', // 3
    'CO2 4', // 4
    'Duty', // 5
    'Set Point', // 6
    'Low PPM', // 7
    'High PPM', // 8
    'Min Vent', // 9
  ]

  let ventilationAxix = {}
  // if 2 connect to economizer ( show low_ppm and high_ppm) on left
  if (mode === '2') {
    const indices = [9]
    labels = labels.filter((_, i) => !indices.includes(i))
  } else if (mode === '1') {
    // if 1 ventilation (0-100) on right
    const indices = [7, 8]
    labels = labels.filter((_, i) => !indices.includes(i))
    ventilationAxix = {
      'Min Vent': {
        axis: 'y2',
        // valueRange: [0, 100]
        // plotter: window.SmoothPlotter,
      },
    }
  } else if (mode === '3') {
    // If 3 show set_point ) on left
    const indices = [7, 8, 9]
    labels = labels.filter((_, i) => !indices.includes(i))
  }


  console.log(labels)
  console.log('datum', datum)

  const dygraphOptsCo2 = {
    height: 320,
    labels: labels,
    series: {
      // 'CO2 1': {
      //   axis: 'y1'
      // },
      'Duty': {
        axis: 'y2',
        // valueRange: [0, 100]
        // plotter: window.SmoothPlotter,
      },
      ...ventilationAxix
    },
    axes: {
      y2: {
        valueRange: [0, 110],
        axisLabelFormatter: function(y) {
          return y.toFixed(0)
        },
        axisLabelWidth: 50,
        independentTicks: true,
        ticker: function(min, max, pixels, opts, dygraph, vals) {
          const ticks = []
          for (let i = min; i <= max; i += 10) {
            ticks.push({ v: i, label: i.toFixed(0) })
          }
          return ticks
        }
      }
    },
    colors: ['#383838', '#5B5B5B', '#8E8E8E', '#C6C6C6', '#F21818', '#159B00', '#5998D1', '#1C579F',],
    //["#C6C6C6", "#8E8E8E", "#5B5B5B", "#383838"],
    labelsDiv: 'legendDivCo2',
    labelsSeparateLines: false,
    legend: 'always',
    animatedZooms: true,
  }
  // console.log('dygraphOptsCo2', dygraphOptsCo2)
  return {
    mode,
    co2_cnfg_dcv: cnfg_dcv,
    options: dygraphOptsCo2,
    data: datum
  }
}


const dygraphOptsRelay = {
  height: 320,
  labels: [
    'Datetime', 
    '1st Stage Cool', 
    '2nd Stage Cool', 
    '1st Stage Heat', 
    '2nd Stage Heat', 
    'Fan'
  ],
  colors: ['#5998D1', '#1C579F', '#F21818', '#BC0808', '#159B00'],
  labelsDiv: 'legendDivRelay',
  axes: {
    y: {
      stepPlot: true,
      axisLabelWidth: 100,
      ticker: function (min, max, pixels, opts, dygraph, vals) {
        return [
          { v: 3, label: '1st Stage Cool' },
          { v: 7, label: '2nd Stage Cool' },
          { v: 11, label: '1st Stage Heat' },
          { v: 15, label: '2nd Stage Heat' },
          { v: 19, label: 'Fan' },
        ]
      },
      valueFormatter: function (y) {
        switch (y) {
          case 2:
            return 'OFF'
            break
          case 4:
            return 'ON'
            break
          case 6:
            return 'OFF'
            break
          case 8:
            return 'ON'
            break
          case 10:
            return 'OFF'
            break
          case 12:
            return 'ON'
            break
          case 14:
            return 'OFF'
            break
          case 16:
            return 'ON'
            break
          case 18:
            return 'OFF'
            break
          case 20:
            return 'ON'
            break
          case 22:
            return 'OFF'
            break
          case 24:
            return 'ON'
            break
          case 26:
            return 'OFF'
            break
          case 28:
            return 'ON'
            break
        }
      },
    },
  },
  legend: 'always',
  animatedZooms: true,
}

function checkIfNonThermastat(deviceInfo) {
  console.log('deviceInfo', deviceInfo)
  return (deviceInfo && deviceInfo.dt && deviceInfo.dt === 'AQ Indoor' || deviceInfo && deviceInfo.deviceType && deviceInfo.deviceType === 3)
}

//  Input: Some array of data & some new array of matching data.
//  Output: One concatenated array, with the appropriate
//    number of items removed from the front.
//    The number of items removed from the front should match the number
//    added to the end.
function combineData(arrOld, arrNew) {
  let result = arrOld.concat(arrNew)
  if (arrOld.length > arrNew.length) result = result.slice(arrNew.length, result.length)
  return result
}

let configCache

class DataAnalysis extends React.Component {
  constructor() {
    super()

    this.graphTempDiv = null
    this.graphRelayDiv = null
    this.graphCo2Div = null
    // Non thermastat charts
    this.graphVoCDiv = null
    this.graphSpsDiv = null

    this.setGraphTempDiv = (element) => {
      this.graphTempDiv = element
    }

    this.setGraphRelayDiv = (element) => {
      this.graphRelayDiv = element
    }

    this.setGraphCo2Div = (element) => {
      this.graphCo2Div = element
    }

    // Non thermastat charts
    this.setGraphVoCDiv = (element) => {
      this.graphVoCDiv = element
    }

    this.setGraphSpsDiv = (element) => {
      this.graphSpsDiv = element
    }

    this.state = {
      allData: [],
      relevantTempDataLive: [],
      relevantRelayDataLive: [],
      relevantTempDataPast: [],
      relevantRelayDataPast: [],
      relevantCo2DataLive: [],
      relevantCo2DataPast: [],
      relevantVocDataLive: [],
      relevantVocDataPast: [],
      relevantSpsDataLive: [],
      relevantSpsDataPast: [],
      apiCalling: false,
      deviceRelayConfig: {},
      deviceName: null,
      deviceMac: null,
      viewMode: 'live',
      deviceEt: 60,
      //Graphs we maintain in state
      temperatureGraph: undefined,
      relayGraph: undefined,
      co2Graph: undefined,
      vocGraph: undefined,
      spsGraph: undefined,
      co2_cnfg_dcv: {},
    }
  }

  componentDidMount() {
    //  Initial time parameters are declared and stored in state.
    let endTime = Moment()
    let startTime = Moment(endTime).subtract(12, 'hours')

    getDevice(this.props.siteID, this.props.deviceID).then((res) => {
      //  Get Live Data and draw graphs.
      //  Also, set up the interval by which we will continue to get Live Data and draw graphs.

      console.log('~~~~~~~~~~~ configuration is: ', res.data.device.configuration)
      if (!res.data.device.configuration.hasOwnProperty('et')) {
        console.log('configuration is missing et, setting to 60')
        res.data.device.configuration.et = 60
      }
      this.setState(
        {
          endTime: endTime,
          startTime: startTime,
          //deviceRelayConfig: res.data.device.configuration[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.RELAY_CONFIG]],
          deviceName: res.data.device.configuration.lbl || res.data.device.name,
          deviceMac: res.data.device.mac_address,
          deviceEt: res.data.device.configuration.et,
        },
        () => {
          this.getLiveDataAndDrawGraphs()
          let regRefreshDataAndGraphs = setInterval(this.getLiveDataAndDrawGraphs, this.state.deviceEt * 1000)
          this.setState({
            regRefreshDataAndGraphs: regRefreshDataAndGraphs,
          })
        },
      )
    })
  }

  componentWillUnmount() {
    if (this.state.regRefreshDataAndGraphs) {
      clearInterval(this.state.regRefreshDataAndGraphs)
    }
  }

  //  If Live mode is selected, populates graphs with live data and sets up
  //    regular check-ins.
  //  If Past mode is selected, clears regular check-ins.
  handleChangeViewMode = (newMode) => {
    this.setState(
      {
        viewMode: newMode,
      },
      () => {
        if (this.state.viewMode === 'live') {
          this.drawAllGraphs({
            tempData: this.state.relevantTempDataLive,
            relayData: this.state.relevantRelayDataLive,
            co2Data: this.state.relevantCo2DataLive,
            vocData: this.state.relevantVocDataLive,
            spsData: this.state.relevantSpsDataLive,
            co2ChartOptions: {
              mode: this.state.co2Mode,
            },
            shouldRedraw: true,
            caller: 'handleChangeViewMode live'
          })
          let regRefreshDataAndGraphs = setInterval(this.getLiveDataAndDrawGraphs, this.state.deviceEt * 1000)
          this.setState({
            regRefreshDataAndGraphs: regRefreshDataAndGraphs,
          })
        } else if (this.state.viewMode === 'past') {
          this.drawAllGraphs({
            tempData: this.state.relevantTempDataPast,
            relayData: this.state.relevantRelayDataPast,
            co2Data: this.state.relevantCo2DataPast,
            vocData: this.state.relevantVocDataPast,
            spsData: this.state.relevantSpsDataPast,
            co2ChartOptions: {
              mode: this.state.co2Mode,
            },
            shouldRedraw: true,
            caller: 'handleChangeViewMode past'
          })
          clearInterval(this.state.regRefreshDataAndGraphs)
        }
      },
    )
  }

  getLiveDataAndDrawGraphs = () => {
    const isNonThermastat = checkIfNonThermastat(this.props.deviceInfo)
    this.setState({ apiCalling: true })
    getBulkData(this.props.siteID, this.props.deviceID, this.state.startTime, this.state.endTime).then((res) => {
      console.log('getBulkData bulk', res.data)
      //res.data = this.removeAnomalousData(res.data);
      const co2Data = combineData(
        this.state.relevantCo2DataLive,
        DataArrBuilder.buildCo2DataArr(res.data),
      )
      let lastItem = res.data[res.data.length - 1] 
      if (!lastItem && configCache) {
        lastItem = configCache
      }
      if (lastItem) {
        configCache = lastItem
      }
      const lastItemCnf = (lastItem || {}).cnfg_dcv
      console.log('co2Data', co2Data)
      console.log('lastItem', lastItem)
      if (lastItem) {
        console.log('lastItem.cnfg_dcv', lastItemCnf)
      }

      let vocDataLive = []
      let spsDataLive = []
      if (this.props.deviceInfo && this.props.deviceInfo.dt && isNonThermastat) {
        vocDataLive = combineData(
          this.state.relevantVocDataLive,
          DataArrBuilder.buildVoCDataArr(res.data),
        )

        spsDataLive = combineData(
          this.state.relevantSpsDataLive,
          DataArrBuilder.buildSpsDataArr(res.data),
        )
      }

      console.log('vocDataLive', vocDataLive)
      console.log('spsDataLive', spsDataLive)

      this.setState(
        {
          allData: this.state.allData.concat(res.data),
          relevantTempDataLive: combineData(
            this.state.relevantTempDataLive,
            DataArrBuilder.buildTemperatureDataArr(res.data),
          ),
          relevantRelayDataLive: combineData(
            this.state.relevantRelayDataLive,
            DataArrBuilder.buildRelayDataArr(res.data, this.state.deviceRelayConfig),
          ),
          relevantCo2DataLive: co2Data,
          // non thermastat chart data
          relevantVocDataLive: vocDataLive,
          relevantSpsDataLive: spsDataLive,
          // Time parameters are updated for next time.
          startTime: this.state.endTime,
        },
        () => {
          const chartDetails = getCo2ChartOptions(lastItemCnf, this.state.relevantCo2DataLive)
          this.setState({ 
            endTime: Moment(),
            co2Mode: chartDetails.mode,
            co2_cnfg_dcv: chartDetails.co2_cnfg_dcv,
          })
          this.drawAllGraphs({
            tempData: this.state.relevantTempDataLive,
            relayData: this.state.relevantRelayDataLive,
            co2Data: this.state.relevantCo2DataLive,
            vocData: this.state.relevantVocDataLive,
            spsData: this.state.relevantSpsDataLive,
            co2ChartOptions: chartDetails,
            caller: 'getLiveDataAndDrawGraphs'
          })
          this.setState({ apiCalling: false })
        },
      )
    })
  }

  getPastDataAndDrawGraphs = (startTime, endTime) => {
    console.log('getPastDataAndDrawGraphs', startTime, endTime)
    const isNonThermastat = checkIfNonThermastat(this.props.deviceInfo)
    this.setState({ apiCalling: true })
    getBulkData(this.props.siteID, this.props.deviceID, startTime, endTime).then((res) => {
      console.log('Past data', res)
      res.data = this.removeAnomalousData(res.data)

      let vocDataPast = []
      let spsDataPast = []
      if (this.props.deviceInfo && this.props.deviceInfo.dt && isNonThermastat) {
        vocDataPast = combineData(
          this.state.relevantVocDataPast,
          DataArrBuilder.buildVoCDataArr(res.data),
        )

        spsDataPast = combineData(
          this.state.relevantSpsDataPast,
          DataArrBuilder.buildSpsDataArr(res.data),
        )
      }

  

      const tempData = DataArrBuilder.buildTemperatureDataArr(res.data)
      const relayData = DataArrBuilder.buildRelayDataArr(res.data, this.state.deviceRelayConfig)
      const co2Data = DataArrBuilder.buildCo2DataArr(res.data)
      //*
      console.log('tempData past', tempData)
      console.log('relayData past', relayData)
      console.log('co2Data past', co2Data)
      console.log('vocDataPast past', vocDataPast)
      console.log('spsDataPast past', spsDataPast)
      /** */


      this.setState(
        {
          allPastData: res.data,
          relevantTempDataPast: tempData,
          relevantRelayDataPast: relayData,
          relevantCo2DataPast: co2Data,
          relevantVocDataPast: vocDataPast,
          relevantSpsDataPast: spsDataPast,
        },
        () => {
          this.drawAllGraphs({
            tempData: this.state.relevantTempDataPast,
            relayData: this.state.relevantRelayDataPast,
            co2Data: this.state.relevantCo2DataPast,
            vocData: this.state.relevantVocDataPast,
            spsData: this.state.relevantSpsDataPast,
            shouldRedraw: true,
            co2ChartOptions: {
              mode: this.state.co2Mode,
            },
            caller: 'getPastDataAndDrawGraphs'
          })
          this.setState({ apiCalling: false })
        },
      )
    })
  }

  removeAnomalousData = (dataArr) => {
    console.log('dataanalysis removeAnomalousData() dataArr: ', dataArr)
    //console.log();
    let newDataArr = []

    // for (let i = 0; i < dataArr.length; i++) {
    //   dataArr[i].tstat_read_data.zone_temp

    //   //First datapoint
    //   if(i == 0){
    //     if(Math.abs(dataArr[i].tstat_read_data.zone_temp - dataArr[i+1].tstat_read_data.zone_temp) >= 35){
    //       //do nothing
    //       console.log("skipping anomoly data dataanalysis");
    //       continue;
    //     }
    //   }
    //   //Inbetween datapoints
    //   else if(i !== dataArr.length-2){
    //     //zone temp jumped by over 35 degrees in one ET period, must be an anomoly
    //     console.log("inbetween datapoints dataanalysis, i-1 zone_temp: ", dataArr[i-1].tstat_read_data.zone_temp, " i zone_temp: ", dataArr[i].tstat_read_data.zone_temp);
    //     if(Math.abs(dataArr[i-1].tstat_read_data.zone_temp - dataArr[i].tstat_read_data.zone_temp) >= 35){
    //       //do nothing
    //       console.log("skipping anomoly data dataanalysis");
    //       continue;
    //     }
    //     else if(Math.abs(dataArr[i].tstat_read_data.zone_temp - dataArr[i+1].tstat_read_data.zone_temp) >= 35){
    //       //do nothing
    //       console.log("skipping anomoly data dataanalysis");
    //       continue;
    //     }
    //   }
    //   //Last datapoint
    //   else{
    //     if(Math.abs(dataArr[i-1].tstat_read_data.zone_temp - dataArr[i].tstat_read_data.zone_temp) >= 35){
    //       //do nothing
    //       console.log("skipping anomoly data dataanalysis");
    //       continue;
    //     }
    //   }
    //   newDataArr.push(dataArr[i]);
    // }

    if (dataArr.length > 1) {
      //Running lastGoodZoneTemp version of anomaly detection
      var lastGoodZoneTemp = dataArr[0].tstat_read_data.zone_temp
      for (let i = 1; i < dataArr.length - 1; i++) {
        //console.log(dataArr[i].tstat_read_data.zone_temp);

        //Check for under 40 f degrees zone temp anomoly
        try {
          var parsedZoneTemp = parseFloat(dataArr[i].tstat_read_data.zone_temp)
          if (parsedZoneTemp < 40) {
            continue
          }
        } catch (e) {
          console.log('error in dataanalysis removeAnomalousData() ', e)
          continue
        }

        //Check for zone temp anomoly by last good zone temp
        if (Math.abs(lastGoodZoneTemp - dataArr[i].tstat_read_data.zone_temp) >= /*30*/ 12) {
          //do nothing
          console.log('skipping anomoly data dataanalysis')
          continue
        }

        // lastGoodZoneTemp = dataArr[i].tstat_read_data.zone_temp;
        newDataArr.push(dataArr[i])
      }

      //Remove zone temp under 40
      // for (let i = 1; i < dataArr.length-1; i++) {
      //   try{
      //     var parsedZoneTemp = parseFloat(dataArr[i].tstat_read_data.zone_temp);
      //     if(parsedZoneTemp < 40){
      //       continue;
      //     }
      //   }
      //   catch(e){
      //     console.log("error in dataanalysis removeAnomalousData() ", e);
      //     continue;
      //   }
      //   newDataArr.push(dataArr[i]);
      // }
    } else {
      newDataArr = dataArr
    }

    return newDataArr
  }

  drawAllGraphs = ({ 
    tempData, 
    relayData, 
    co2Data, 
    vocData, 
    spsData, 
    co2ChartOptions, 
    shouldRedraw, 
    caller 
  }) => {
    console.log('draw All Graphs caller', caller)

    console.log('drawAllGraphs data', { 
      tempData, 
      relayData, 
      co2Data, 
      vocData, 
      spsData, 
      co2ChartOptions, 
      shouldRedraw, 
      caller 
    })

    let temperatureGraph
    if (tempData.length){
      temperatureGraph = this.drawTemperatureGraph(tempData, shouldRedraw)
    }

    let relayGraph
    if (relayData.length) {
      relayGraph = this.drawRelayGraph(relayData, shouldRedraw)
    }

    let co2Graph
    if (co2Data.length) {
      co2Graph = this.drawCo2Graph(co2Data, co2ChartOptions, shouldRedraw)
    }

    let vocGraph
    if (vocData.length) {
      vocGraph = this.drawVoCGraph(vocData, shouldRedraw)
    }
    let spsGraph
    if (spsData.length) {
      spsGraph = this.drawSpsGraph(spsData, shouldRedraw)
    }

    if (this.sync === undefined) {
      if (vocGraph && spsGraph) {
        const dataArray = (!temperatureGraph || !relayGraph) ? [co2Graph, vocGraph, spsGraph] : [temperatureGraph, relayGraph, co2Graph, vocGraph, spsGraph]
        this.sync = Dygraph.synchronize(dataArray, {
          range: false,
        })
      } else {
        if (!temperatureGraph && !relayGraph && !co2Graph) {
          return
        }
        this.sync = Dygraph.synchronize([temperatureGraph, relayGraph, co2Graph], {
          range: false,
        })
      }
    }
  }

  drawTemperatureGraph = (relevantDataArr, shouldRedraw) => {
    console.log('drawTemperatureGraph relevantDataArr', relevantDataArr)
    //  Index 0 is datetime string. We will change it to a date object.
    //  Index 1 is a temperature string that represents a float. We will
    //    change it to an actual float.
    //  Indexes 2 and 3 are heat and cool setpoints respectively, parsed
    //    the same way as the zone temp.
    let newRelevantDataArr = relevantDataArr.map((dataItem, i) => {
      let resultItem = []
      resultItem[0] = new Date(dataItem[0])
      resultItem[1] = parseFloat(dataItem[1])
      resultItem[2] = parseFloat(dataItem[2])
      resultItem[3] = parseFloat(dataItem[3])
      resultItem[4] = parseFloat(dataItem[4])
      resultItem[5] = parseFloat(dataItem[5])
      resultItem[6] = parseFloat(dataItem[6])
      resultItem[7] = parseFloat(dataItem[7])
      return resultItem
    })

    if (this.temperatureGraph === undefined) {
      console.log('Drawing new temperature graph')
      let graphDiv = this.graphTempDiv
      this.temperatureGraph = new Dygraph(graphDiv, newRelevantDataArr, dygraphOptsTemp)
    } else {
      console.log('Updating existing temperature graph')
      this.temperatureGraph.updateOptions({ file: newRelevantDataArr })

      if (shouldRedraw) {
        this.temperatureGraph.resetZoom()
      }
    }

    return this.temperatureGraph
  }

  drawSpsGraph = (relevantDataArr, shouldRedraw) => {
    console.log('drawSpsGraph relevantDataArr', relevantDataArr)
    //  Index 0 is datetime string. We will change it to a date object.
    //  Index 1 is a temperature string that represents a float. We will
    //    change it to an actual float.
    //  Indexes 2 and 3 are heat and cool setpoints respectively, parsed
    //    the same way as the zone temp.
    let newRelevantDataArr = relevantDataArr.map((dataItem, i) => {
      let resultItem = []
      resultItem[0] = new Date(dataItem[0])
      resultItem[1] = parseFloat(dataItem[1])
      resultItem[2] = parseFloat(dataItem[2])
      return resultItem
    })

    if (this.spsGraph === undefined) {
      console.log('Drawing new spsGraph graph', dygraphOptsSps)
      let graphDiv = this.graphSpsDiv
      this.spsGraph = new Dygraph(graphDiv, newRelevantDataArr, dygraphOptsSps)
    } else {
      console.log('Updating existing spsGraph graph')
      this.spsGraph.updateOptions({ file: newRelevantDataArr })

      if (shouldRedraw) {
        this.spsGraph.resetZoom()
      }
    }

    return this.spsGraph
  }

  drawVoCGraph = (relevantDataArr, shouldRedraw) => {
    console.log('drawVoCGraph relevantDataArr', relevantDataArr)
    //  Index 0 is datetime string. We will change it to a date object.
    //  Index 1 is a temperature string that represents a float. We will
    //    change it to an actual float.
    //  Indexes 2 and 3 are heat and cool setpoints respectively, parsed
    //    the same way as the zone temp.
    let newRelevantDataArr = relevantDataArr.map((dataItem, i) => {
      let resultItem = []
      resultItem[0] = new Date(dataItem[0])
      resultItem[1] = parseFloat(dataItem[1])
      return resultItem
    })

    if (this.vocGraph === undefined) {
      console.log('Drawing new dygraphOptsVoc graph', dygraphOptsVoc)
      let graphDiv = this.graphVoCDiv
      this.vocGraph = new Dygraph(graphDiv, newRelevantDataArr, dygraphOptsVoc)
    } else {
      console.log('Updating existing dygraphOptsVoc graph')
      this.vocGraph.updateOptions({ file: newRelevantDataArr })

      if (shouldRedraw) {
        this.vocGraph.resetZoom()
      }
    }

    return this.vocGraph
  }

  drawRelayGraph = (relevantDataArr) => {
    let newRelevantDataArr = relevantDataArr.map((dataItem, i) => {
      let resultItem = []
      resultItem[0] = new Date(dataItem[0]) // Datetime string.
      //  State of relays 1 thru 7. See DataArrBuilder for meaning of these ints.
      resultItem[1] = parseInt(dataItem[1], 10)
      resultItem[2] = parseInt(dataItem[2], 10)
      resultItem[3] = parseInt(dataItem[3], 10)
      resultItem[4] = parseInt(dataItem[4], 10)
      resultItem[5] = parseInt(dataItem[5], 10)
      resultItem[5] = parseInt(dataItem[5], 10)
      return resultItem
    })

    if (this.relayGraph === undefined) {
      let graphDiv = this.graphRelayDiv
      this.relayGraph = new Dygraph(graphDiv, newRelevantDataArr, dygraphOptsRelay)
    } else {
      console.log('newRelevantDataArr', newRelevantDataArr)
      this.relayGraph.updateOptions({ file: newRelevantDataArr })
    }

    return this.relayGraph
  }

  drawCo2Graph = (relevantDataArr, chartOptions, shouldRedraw) => {
    console.log('mode', chartOptions)
    let newRelevantDataArr = relevantDataArr.map((dataItem, i) => {
      let resultItem = []
      resultItem[0] = new Date(dataItem[0]) // Datetime string.
      //  State of relays 1 thru 7. See DataArrBuilder for meaning of these ints.
      // co2 1
      resultItem[1] = parseFloat(dataItem[1], 10)
      // co2 2
      resultItem[2] = parseFloat(dataItem[2], 10)
      // co2 3
      resultItem[3] = parseFloat(dataItem[3], 10)
      // co2 4
      resultItem[4] = parseFloat(dataItem[4], 10)
      // duty
      resultItem[5] = parseFloat(dataItem[5], 10)
      // low ppm
      resultItem[6] = parseFloat(dataItem[6], 10)
      // high ppm
      resultItem[7] = parseFloat(dataItem[7], 10)
      // set_point
      resultItem[8] = parseFloat(dataItem[8], 10)
      // min vent
      resultItem[9] = parseFloat(dataItem[9], 10)

      // Conditional display of data
      if (chartOptions.mode === '2') {
        const indices = [9]
        return resultItem.filter((_, i) => !indices.includes(i))
      } else if (chartOptions.mode === '1') {
        // if 1 ventilation (0-100) on right
        const indices = [7, 8]
        return resultItem.filter((_, i) => !indices.includes(i))
      } else if (chartOptions.mode === '3') {
        // If 3 show set_point ) on left
        const indices = [7, 8, 9]
        return resultItem.filter((_, i) => !indices.includes(i))
      }

      return resultItem
    })

    // console.log('newRelevantDataArr', newRelevantDataArr);
    if (this.co2Graph === undefined) {
      let graphDiv = this.graphCo2Div
      console.log('DRAW co2 GRAPH', newRelevantDataArr)
      this.co2Graph = new Dygraph(graphDiv, newRelevantDataArr, chartOptions.options)
    } else {
      console.log('UPDATE co2 GRAPH', newRelevantDataArr)
      this.co2Graph.updateOptions({
        file: newRelevantDataArr 
      })
    }

    return this.co2Graph
  }

  render() {
    const isNonThermastat = checkIfNonThermastat(this.props.deviceInfo)
    const showThermaStatGridItem = { display: isNonThermastat ? 'none' : 'initial'}
    const showNonThermaStatGridItem = { display: isNonThermastat ? 'initial' : 'none'}
    const co2_cnfg_dcv = this.state.co2_cnfg_dcv
    console.log('this.state.allData bulk', this.state.allData)
    return (
      <div>
        <Row>
          <Col md={4} sm={6} xs={6}>
            <span style={{ fontSize: '2em', marginRight: '8px' }}>
              {this.state.deviceName ? this.state.deviceName : 'loading...'}
            </span>
            <Spinner active={this.state.apiCalling} />
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={4} xs={4}>
            <div>
              <b>Mac Address: {this.state.deviceName ? this.state.deviceMac : 'loading...'}</b>
            </div>
            <div>
              <b>Data Trending</b>
            </div>
            
          </Col>
          <Col md={4} sm={4} xs={4}>
            <PastDatePick
              viewMode={this.state.viewMode}
              handleChangeViewMode={this.handleChangeViewMode}
              getPastDataAndDrawGraphs={this.getPastDataAndDrawGraphs}
            />
          </Col>
          <Col md={4} sm={4} xs={4}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <DownloadCSVButton 
                data={this.state.allData}
                pastData={this.state.allPastData}
                macId={this.state.deviceMac} 
                view={this.state.viewMode}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={12} style={showThermaStatGridItem}>
            <XPanel>
              <XPanel.Title title='Temperature Over Time'></XPanel.Title>
              <XPanel.Content>
                <div id='legendDivTemp' className='legend' />
                <div style={{ minHeight: '320px' }} ref={this.setGraphTempDiv}></div>
              </XPanel.Content>
            </XPanel>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <XPanel>
              <XPanel.Title title='Ventilation Over Time'></XPanel.Title>
              <XPanel.Content>
                <div style={{ display: 'flex' }}>
                  {co2_cnfg_dcv && co2_cnfg_dcv.mode && <div style={{ marginRight: '8px' }}>Mode: {modeMap[co2_cnfg_dcv.mode]}</div>}
                  {co2_cnfg_dcv && co2_cnfg_dcv.low_ppm && <div style={{ marginRight: '8px' }}>Low PPM: {co2_cnfg_dcv.low_ppm}</div>}
                  {co2_cnfg_dcv && co2_cnfg_dcv.high_ppm && <div style={{ marginRight: '8px' }}>High PPM: {co2_cnfg_dcv.high_ppm}</div>}
                </div>
                <div id='legendDivCo2' className='legend' />
                <div style={{ minHeight: '320px' }} ref={this.setGraphCo2Div} />
              </XPanel.Content>
            </XPanel>
          </Col>
          <Col md={6} sm={6} xs={12} style={showNonThermaStatGridItem}>
            <XPanel>
              <XPanel.Title title='Volatile gases over time'></XPanel.Title>
              <XPanel.Content>
                <div id='legendDivVoc' className='legend' />
                <div style={{ minHeight: '320px' }} ref={this.setGraphVoCDiv} />
              </XPanel.Content>
            </XPanel>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={12} style={showThermaStatGridItem}>
            <XPanel>
              <XPanel.Title title='Relays Over Time'></XPanel.Title>
              <XPanel.Content>
                <div id='legendDivRelay' className='legend' />
                <div style={{ minHeight: '320px' }} ref={this.setGraphRelayDiv} />
              </XPanel.Content>
            </XPanel>
          </Col>
          <Col md={6} sm={6} xs={12} style={showNonThermaStatGridItem}>
            <XPanel>
              <XPanel.Title title='Fine particulates over time'></XPanel.Title>
              <XPanel.Content>
                <div id='legendDivSps' className='legend' />
                <div style={{ minHeight: '320px' }} ref={this.setGraphSpsDiv} />
              </XPanel.Content>
            </XPanel>
          </Col>
          <Col md={6} sm={6} xs={12}></Col>
        </Row>
        
        <CommentsSection 
          itemId={this.state.deviceMac} 
          
        />
      </div>
    )
  }
}

//
const BASE_URL = 'https://405l17ip5f.execute-api.us-west-1.amazonaws.com/prod'

function CommentsSection({ itemId }) {
  const [ comments, setComments] = useState([])
  const [ lastUpdate, setLastUpdate ] = useState(null)

  if (!itemId) {
    return <div>loading notes</div>
  }
  // Update the comments list when a new comment is added
  function handleCommentAdded(newComment) {
    // setComments((prevComments) => [newComment, ...prevComments])
    setLastUpdate(new Date().toISOString())
  }

  return (
    <div>
      <h3>Notes</h3>
      <CommentList itemId={itemId} lastUpdate={lastUpdate} />
      <AddComment itemId={itemId} onCommentAdded={handleCommentAdded} />
    </div>
  )
}

function AddComment({ itemId, onCommentAdded }) {
  const [commentText, setCommentText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  // Handle form submission
  async function handleSubmit(event) {
    event.preventDefault()

    if (!commentText) {
      return alert('Please enter a comment')
    }

    setIsLoading(true)
    setError(null)

    try {
      const response = await fetch(`${BASE_URL}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ itemId, commentText }),
      })

      if (!response.ok) {
        throw new Error('Failed to add comment')
      }

      const data = await response.json()
      onCommentAdded(data)  // Notify parent component that a new comment was added
      setCommentText('')    // Clear the comment input
    } catch (error) {
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} style={{ marginLeft: 20, marginTop: 20}}>
      <textarea
        style={{ display: 'block', width: '460px', minHeight: '180px', marginBottom: '8px' }}
        value={commentText}
        onChange={(e) => setCommentText(e.target.value)}
        placeholder="Add a new note to this device"
      />
      <Button type="submit" disabled={isLoading} className="btn btn-primary">
        {isLoading ? 'Adding...' : 'Add Note to this device'}
      </Button>

      {error && <p style={{ color: 'red' }}>Error: {error}</p>}
    </form>
  )
}

function CommentList({ itemId, lastUpdate }) {
  const [comments, setComments] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  async function deleteComment(commentId) {
    // Show the browser's built-in confirm dialog
    const isConfirmed = window.confirm('Are you sure you want to delete this comment?')

    if (!isConfirmed) {
      return // User clicked "Cancel", exit the function
    }

    try {
      const response = await fetch(`${BASE_URL}/comments/${itemId}/${commentId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      })

      if (!response.ok) {
        throw new Error('Failed to delete comment')
      }

      // Update the state to remove the deleted comment
      setComments(comments.filter((comment) => comment.commentId !== commentId))
    } catch (error) {
      console.error(error)
    }
  }

  // Fetch comments when the component mounts
  useEffect(() => {
    async function fetchComments() {
      setIsLoading(true)
      setError(null)

      try {
        const response = await fetch(`${BASE_URL}/comments/${itemId}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })

        if (!response.ok) {
          throw new Error('Failed to fetch comments')
        }

        const data = await response.json()
        setComments(data)
      } catch (error) {
        setError(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    fetchComments()
  }, [itemId, lastUpdate])

  if (isLoading) return <p>Loading comments...</p>
  if (error) return <p>Error loading comments: {error}</p>

  return (
    <ul style={{ paddingLeft: 20 }}>
      {comments.map((comment) => (
        <li key={comment.commentId} className='notes-item'>
          <div>{new Date(comment.commentId).toLocaleString()}</div>
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent:'space-between' }}>
            <div style={{ paddingTop: 8, marginRight: 20, maxWidth: 480, minHeight: 40 }}>
              {comment.commentText}
            </div>
            <div className='notes-action-section'>
              <Button onClick={() => deleteComment(comment.commentId)} className='btn-sm'>
                Delete comment
              </Button>
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}

/**
 * Flattens a nested object, converting nested keys into dot notation
 * @param {Object} obj - The nested object to flatten
 * @param {String} parent - Parent key (for recursive calls)
 * @param {Object} res - The result object (for recursive calls)
 * @returns {Object} - The flattened object
 */
function flattenObject(obj, parent = '', res = {}) {
  for (let key in obj) {
    const propName = parent ? `${parent}.${key}` : key
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      flattenObject(obj[key], propName, res)
    } else {
      res[propName] = obj[key]
    }
  }
  return res
}

/**
 * Converts an array of objects into CSV format with flattened key paths
 * @param {Array} data - The array of objects to convert
 * @returns {String} - The CSV string
 */
function arrayToCSV(data) {
  const flattenedData = data.map(item => flattenObject(item))

  // Get unique headers from all the keys of the flattened objects
  let headers = [...new Set(flattenedData.flatMap(item => Object.keys(item)))]

  // Move 'mac_address', 'device_id', and 'datetime' to the first three columns in that order
  headers = headers.filter(header => !['mac_address', 'device_id', 'datetime'].includes(header))
  headers.unshift('datetime')
  headers.unshift('device_id')
  headers.unshift('mac_address')

  // Create CSV rows, starting with headers
  const csvRows = [headers.join(',')]

  // Add data rows
  flattenedData.forEach(item => {
    const row = headers.map(header => item[header] || '').join(',')
    csvRows.push(row)
  })

  return csvRows.join('\n')
}

const DownloadCSVButton = ({ data, pastData, macId, view }) => {
  
  const dataToUse = (view === 'live') ? data : (pastData || [])

  const downloadCSV = () => {
    if (!data) {
      return
    }
    if (!macId) {
      return
    }

    // Generate CSV string
    const csv = arrayToCSV(dataToUse)

    // Create a blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv' })

    // Create a link element
    const link = document.createElement('a')

    // Set the download attribute with a filename
    link.download = `device-data-${macId}.csv`

    // Create an object URL for the Blob
    link.href = URL.createObjectURL(blob)

    // Programmatically click the link to trigger the download
    link.click()

    // Clean up by revoking the Object URL
    URL.revokeObjectURL(link.href)
  }

  if (dataToUse.length === 0) {
    return null
  }

  
  return (
    <button onClick={downloadCSV}>
      Download as CSV
    </button>
  )
}

export default DataAnalysis
